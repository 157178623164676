.video-section {
  padding: clamp(40px, 6vw, 80px) 0;
  position: relative;
}

.video-box {
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border-radius: clamp(20px, 3vw, 64px);
  text-align: center;
  padding: clamp(20px, 3vw, 60px) clamp(15px, 3vw, 50px);
  margin-top: clamp(-20px, -3vw, -60px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.video-box h2 {
  font-size: clamp(24px, 3vw, 45px);
  font-weight: 700;
  margin-bottom: clamp(15px, 2vw, 30px);
  background: linear-gradient(90deg, #ffffff 0%, #d4c0ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: clamp(1px, 0.2vw, 2px);
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  border-radius: clamp(10px, 2vw, 20px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  transition: transform 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 991px) {
  .video-box {
    margin-top: 0;
    padding: clamp(15px, 2.5vw, 40px);
  }
}

@media (max-width: 768px) {
  .video-box {
    border-radius: 20px;
    padding: 20px 15px;
  }

  .video-box h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .video-container {
    border-radius: 10px;
  }
}

@media (max-width: 480px) {
  .video-section {
    padding: 30px 0;
  }

  .video-box {
    border-radius: 15px;
    padding: 15px 10px;
  }

  .video-box h2 {
    font-size: 24px;
    margin-bottom: 12px;
  }
} 