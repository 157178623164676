.language-switcher {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.language-select {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
  padding-right: 30px;
}

.language-select:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.language-select option {
  background-color: #121212;
  color: white;
}

@media (max-width: 768px) {
  .language-switcher {
    margin: 10px 0;
  }
} 