.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon-container {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  gap: 8px;
}

.social-icon-small {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: rgba(217, 217, 217, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  transition: all 0.3s ease;
  color: white;
  font-size: 18px;
}

.social-icon-small:hover {
  background: rgba(255, 255, 255, 1);
  border-color: rgba(255, 255, 255, 1);
  color: #121212;
  transform: translateY(-2px);
}

.social-icon-small svg {
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .social-icon-container {
    flex-direction: row;
    margin-bottom: 10px;
    gap: 10px;
  }
}

@media (max-width: 991px) {
  .desktop-menu {
    display: none !important;
  }

  .mobile-menu-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .mobile-language {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .mobile-nav-links {
    padding: 15px 0;
    text-align: center;
  }

  .mobile-social-icons {
    display: flex;
    justify-content: center;
    padding: 15px 0;
  }

  .mobile-menu-wrapper .navbar-text {
    display: flex;
    justify-content: center;
    padding: 15px 0;
  }
}

@media (min-width: 992px) {
  .mobile-menu-wrapper {
    display: none;
  }

  .desktop-menu {
    display: flex !important;
  }
}

.navbar-logo {
  width: 100px !important;
  height: 100px !important;
  display: inline-block !important;
  object-fit: contain !important;
  max-width: 100% !important;
} 