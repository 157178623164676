.timeline-container {
  position: relative;
  max-width: 1200px;
  margin: clamp(20px, 3vw, 40px) auto;
  padding: clamp(10px, 2vw, 20px);
}

.timeline-container::before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
}

.timeline-item {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  padding-top: 35px;
}

.timeline-item.left .timeline-card {
  margin-right: calc(50% + 50px);
}

.timeline-item.right .timeline-card {
  margin-left: calc(50% + 50px);
}

.timeline-dot {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
}

.timeline-date {
  position: absolute;
  top: 0;
  color: #fff;
  font-size: clamp(14px, 1.8vw, 16px);
  font-weight: 500;
}

.timeline-item.left .timeline-date {
  right: 50%;
  margin-right: 30px;
  text-align: right;
}

.timeline-item.right .timeline-date {
  left: 50%;
  margin-left: 30px;
}

.timeline-card {
  position: relative;
  width: calc(50% - 50px);
  padding: 20px;
  padding-bottom: 35px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.timeline-card::after {
  content: '↓';
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);
  font-size: clamp(14px, 1.8vw, 16px);
  color: rgba(255, 255, 255, 0.5);
  transition: transform 0.3s ease;
}

.timeline-card.active::after {
  transform: translateX(-50%) rotate(180deg);
}

.timeline-card:hover::after {
  color: rgba(255, 255, 255, 0.8);
}

.timeline-card h3 {
  margin: 0;
  font-size: 20px;
  color: #fff;
}

.timeline-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  margin-bottom: 30px;
}

.timeline-card.active .timeline-content {
  max-height: 500px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.timeline-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.timeline-content li {
  color: rgba(255, 255, 255, 0.7);
  margin: clamp(6px, 1vw, 8px) 0;
  font-size: clamp(14px, 1.8vw, 16px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .timeline-container::before {
    left: 30px;
  }

  .timeline-dot {
    left: 30px;
  }

  .timeline-item.left .timeline-card,
  .timeline-item.right .timeline-card {
    width: calc(100% - 60px);
    margin-left: 60px;
    margin-right: 0;
    padding: 15px;
  }

  .timeline-item.left .timeline-date,
  .timeline-item.right .timeline-date {
    left: 70px;
    right: auto;
    top: 10px;
    font-size: 14px;
    width: calc(100% - 80px);
    white-space: nowrap;
  }

  .timeline-card h3 {
    font-size: 16px;
  }

  .timeline-content li {
    font-size: 14px;
    line-height: 1.4;
    margin: 6px 0;
  }

  .timeline-item {
    padding-top: 45px;
  }

  .timeline-card {
    padding-bottom: 60px;
  }

  .timeline-content {
    margin-bottom: 25px;
  }

  .timeline-card::after {
    bottom: 20px;
  }
}

/* Additional breakpoint for very small screens */
@media (max-width: 480px) {
  .timeline-item.left .timeline-card,
  .timeline-item.right .timeline-card {
    width: calc(100% - 45px);
    margin-left: 45px;
    padding: 12px;
  }

  .timeline-card h3 {
    font-size: 15px;
  }

  .timeline-content li {
    font-size: 13px;
  }

  .timeline-item {
    padding-top: 40px;
  }

  .timeline-item.left .timeline-date,
  .timeline-item.right .timeline-date {
    font-size: 13px;
  }

  .timeline-card {
    padding-bottom: 50px;
  }

  .timeline-content {
    margin-bottom: 20px;
  }

  .timeline-card::after {
    bottom: 15px;
  }

  .timeline-date {
    font-size: clamp(12px, 1.6vw, 14px);
  }
  
  .timeline-card h3 {
    font-size: clamp(14px, 2vw, 18px);
  }
  
  .timeline-content li {
    font-size: clamp(12px, 1.6vw, 14px);
  }
} 