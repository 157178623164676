/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}
/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
  display: flex;
  gap: 10px;
  margin-left: 20px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: 0.3s ease-in-out;
}
.social-icon a svg {
  width: 20px;
  height: 20px;
  color: #fff;
}
.social-icon a:hover {
  background: rgba(255, 255, 255, 1);
  border-color: rgba(255, 255, 255, 1);
}
.social-icon a:hover svg {
  color: #121212;
  transform: scale(1.1);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 220px 0 100px 0;

}
.banner-text-wrapper {
  min-height: 400px; /* Adjust this value based on your design needs */
}
.banner .tagline {
  font-weight: 1000;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 1000;
  letter-spacing: 0.8px;
  line-height: 1.2;
  margin-top: 60px;
}
.txt-rotate {
  display: inline-block;
}
.txt-rotate > .wrap {
  font-size: 55px;
  border-right: 0.08em solid #666;
}
.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}

/************ Skills Css ************/
.skill {
  margin: 0;
  padding: 80px 0;
  position: relative;
}
.skill-bx {
  background: #8f79a32b;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
}
.skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 30px 0 7px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ Projects Css ************/
.project {
  margin: 0;
  padding: 80px 0;
  position: relative;
  padding-top: 60px; /* Adjust this value as needed */
}

.project h2 {
  font-size: clamp(30px, 5vw, 45px);
  font-weight: 700;
  text-align: center;
}

.project p {
  color: #dedede;
  font-size: clamp(14px, 2vw, 18px);
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 90%;
  max-width: 800px;
}

.project-bx {
  background: rgba(255, 255, 255, 0.1);; /* Optional: adds a slight background */;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.project .nav.nav-pills {
  width: 95%;
  margin: 0 auto;
  border-radius: 20px;
  background-color: transparent;
  overflow: hidden;
}

.project .nav.nav-pills .nav-item {
  width: 25%;
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 100px;
  padding: 16px 0;
  color: #fcfcfc;
  width: 100%;
  font-size: clamp(12px, 1.5vw, 17px);
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: clamp(20px, 3vw, 30px);
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: clamp(12px, 1.5vw, 15px);
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/* Media queries for further adjustments */
@media (max-width: 768px) {
  .project .nav.nav-pills .nav-item {
    width: 50%;
  }
  
  .nav-link#projects-tabs-tab-first,
  .nav-link#projects-tabs-tab-second,
  .nav-link#projects-tabs-tab-third {
    border-radius: 0;
  }
}

@media (max-width: 480px) {
  .project .nav.nav-pills .nav-item {
    width: 100%;
  }
}

/************ Contacts Css ************/
.contact {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  margin: 0;
  padding: 80px 0;
}
.newsletter-bx {
  background: #FFFFFF;
  border-radius: 50px;
  color: #121212;
  padding: 30px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}

.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
  align-items: right;
}
.newsletter-button {
  display: inline-block;
  background-color: transparent;
  border: 2px solid #000000;
  border-radius: 50px;
  padding: 14px 28px;
  color: #000000;
  width: auto;
  font-size: clamp(12px, 1.5vw, 17px);
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  cursor: pointer;
}

.newsletter-button::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.newsletter-button:hover::before,
.newsletter-button:focus::before {
  width: 100%;
}

.newsletter-button:hover,
.newsletter-button:focus {
  color: #fff;
  border-color: transparent;
}

.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.footer img {
  width: 40%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .footer p {
    text-align: center;
    margin-top: 30px;
  }
}

/* Who Are We  */
.who-are-we {
  margin: 0;
  padding: 80px 0;
  position: relative;
}

.who-are-we .skill-bx {
  background:#8f79a32b;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.who-are-we h1 {
  font-size: 70px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 50px;
}

.who-are-we-box {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  text-align: center;
  padding: 40px 30px;
  margin-bottom: 30px;
  height: 100%;
  position: relative;
}

.who-are-we-box img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
}

.who-are-we-box h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #ffffff;
}

.who-are-we-box h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #B8B8B8;
}

.who-are-we-box p {
  font-size: 16px;
  color: #B8B8B8;
  line-height: 1.5em;
}

.linkedin-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #0077B5;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: all 0.3s ease;
}

.linkedin-icon:hover {
  transform: scale(1.1);
}

.linkedin-icon img {
  width: 20px;
  height: 20px;
}

@media (max-width: 1199px) {
  .who-are-we h1 {
    font-size: 60px;
  }
}

@media (max-width: 991px) {
  .who-are-we h1 {
    font-size: 50px;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .who-are-we .skill-bx {
    padding: 40px 20px;
  }

  .who-are-we h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }

  .who-are-we-box {
    padding: 30px 20px;
  }
}

@media (max-width: 480px) {
  .who-are-we h1 {
    font-size: 32px;
  }

  .who-are-we-box img {
    width: 120px;
    height: 120px;
  }

  .who-are-we-box h3 {
    font-size: 20px;
  }

  .who-are-we-box h4 {
    font-size: 16px;
  }

  .who-are-we-box p {
    font-size: 14px;
  }
}

/* Benefits Section */
.benefits {
  margin: 0;
  padding: 80px 0;
  position: relative;
}
.benefits p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 90%;
  max-width: 800px;
}
.benefits h1 {
  font-size: 70px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.benefits h2 {
  font-size: 20px;
  font-weight: 100;
  text-align: center;
  margin-bottom: 30px;
}

.benefits-box {
  margin-bottom: 30px; /* Adds space below each box */
  padding: 20px; /* Adds some internal padding */
  background: rgba(255, 255, 255, 0.1); /* Optional: adds a slight background */
  border-radius: 10px; /* Optional: rounds the corners */
}

.benefits-box-content {
  flex: 1;
  padding-right: 20px;
}

.benefits-box img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 20px;
}

.benefits-box h3 {
  font-size: clamp(22px, 3vw, 28px);
  font-weight: 600;
  margin-bottom: 20px;
}

.benefits-box p {
  font-size: clamp(13px, 1.6vw, 16px);
  line-height: 1.5;
  margin-bottom: 10px;
  text-align: left;
}

.benefits-box strong {
  font-size: clamp(14px, 1.7vw, 17px);
}

.benefits-box .centered-text {
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 20px;
  font-weight: 500;
}

.benefits-box p strong {
  color: #ffffff;
  font-weight: 600;
}

@media (min-width: 992px) {
  .benefits .row {
    display: flex;
    flex-wrap: wrap;
  }

  .benefits .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 991px) {
  .benefits h1 {
    font-size: 50px;
  }

  .benefits-box {
    padding: 30px 20px;
  }

  .benefits-box {
    margin-bottom: 40px; /* Increases space between boxes on smaller screens */
  }
}

@media (max-width: 767px) {
  .benefits h1 {
    font-size: 40px;
  }

  .benefits-box h3 {
    font-size: 20px;
  }

  .benefits-box p {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .newsletter-button {
    font-size: 14px;
    padding: 12px 20px;
  }
}

/* Styles for WhoAreWe LinkedIn buttons */
.who-are-we-box .linkedin-icon {
    width: 40px;
    height: 40px;
    background: rgba(217, 217, 217, 0.3);
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    margin-bottom: 15px;
}

.who-are-we-box .linkedin-icon:hover {
    background: rgba(217, 217, 217, 0.5);
}

.who-are-we-box .linkedin-icon img {
    width: 60%;
    height: 60%;
    object-fit: contain;
    filter: brightness(0) invert(1);
}

.who-are-we-box .linkedin-icon svg {
    width: 60%;
    height: 60%;
    fill: white;
}

/* Projects section styles */
.project .nav-pills {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 15px !important;
}

.project .nav-pills .nav-item {
  flex: 1 1 auto;
  margin: 5px;
}

.project .nav-pills .nav-link {
  border-radius: 25px;
  padding: 15px 20px;
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  color: #fff;
  background-color: transparent;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.project .nav-pills .nav-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
  border-radius: 25px;
}

.project .nav-pills .nav-link:hover::before,
.project .nav-pills .nav-link.active::before {
  width: 100%;
}

.project .nav-pills .nav-link span {
  position: relative;
  z-index: 1;
}

.project .nav-pills .nav-link:hover,
.project .nav-pills .nav-link.active {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.project .nav-pills .nav-link:active {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.project .tab-content {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  margin-top: 20px;
  display: inline-block;
  width: auto;
  max-width: 100%;
  border-radius: 10px;
}

.project .tab-pane {
  display: none;
}

.project .tab-pane.active {
  display: block;
}

.project .project-description {
  overflow-y: auto;
  padding: 0;
  margin-top: 10px;
}

.project .project-description h4 {
  margin-bottom: 15px;
}

.project .project-description p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  line-height: 1.5em;
  margin-bottom: 15px;
}

.project .benefits-section {
  margin-top: 15px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .project .nav-pills .nav-item {
    flex: 1 1 100%; /* Full width for 1 item per row */
  }
  
  .project .nav-pills .nav-link {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .project .nav-pills .nav-item:last-child .nav-link {
    border-bottom: none;
  }
}

/* For small screens */
@media (max-width: 575px) {
  .benefits-box {
    margin-bottom: 50px; /* Even more space on very small screens */
  }
}

.why-choose {
  padding: 80px 0;
  position: relative;
}

.why-choose .skill-bx {
  background: #8f79a32b;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.why-choose-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;
  text-align: left;
  transition: transform 0.3s ease;
}

.why-choose-card:hover {
  transform: translateY(-5px);
}

.why-choose-card .icon-wrapper {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 20px;
}

.why-choose-card h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #ffffff;
}

.why-choose-card ul {
  list-style-type: none;
  padding-left: 0;
}

.why-choose-card li {
  color: #B8B8B8;
  font-size: 16px;
  margin-bottom: 15px;
  padding-left: 25px;
  position: relative;
}

.why-choose-card li:before {
  content: "→";
  position: absolute;
  left: 0;
  color: #ffffff;
}

.feature-list-background {
  background: rgba(217, 217, 217, 0.1);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 30px;
}

/* Add this after the existing project nav pills styles */
.category-nav-link[data-category="forDoctors"]::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #4A2FBD -5.91%, #5f5dd3 111.58%); /* Red gradient */
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.category-nav-link[data-category="forDoctors"].active::before,
.category-nav-link[data-category="forDoctors"]:hover::before {
  width: 100%;
}

/* For the inner nav pills in Projects component */
.project .nav.nav-pills .nav-link[data-category="forDoctors"]::before {
  background: linear-gradient(90.21deg, #4A2FBD -5.91%, #5f5dd3 111.58%); /* Red gradient */
}

/* Red gradient for nav links in Projects when hovered or active */
.project .nav-pills .nav-link[data-category="forDoctors"]::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #4A2FBD -5.91%, #5f5dd3 111.58%); /* Red gradient */
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.project .nav-pills .nav-link[data-category="forDoctors"].active::before,
.project .nav-pills .nav-link[data-category="forDoctors"]:hover::before {
  width: 100%;
}

/* Add this for the feature buttons when "For Professionals" is selected */
.project .nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%); /* Default gradient */
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

/* Red gradient for feature buttons when "For Professionals" is selected */
[data-category="forDoctors"] ~ .feature-list-background .nav-pills .nav-link::before {
  background: linear-gradient(90.21deg, #4A2FBD -5.91%, #5f5dd3 111.58%); /* Red gradient */
}

.benefits-section ul {
  list-style-type: none;
  padding-left: 0;
}

.benefits-section li {
  padding: 8px 0;
  position: relative;
  padding-left: 25px;
  font-family: 'Centra', sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  margin-bottom: 0;
}

.benefits-section li:before {
  content: none;
}

/* Check Our MVP button styles */
.navbar-text .mvp-btn {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  position: relative;
  background-color: #000;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
  overflow: hidden;
}

/* Gradient hover effect */
.navbar-text .mvp-btn::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text .mvp-btn:hover::before {
  width: 100%;
}

/* Box-shadow shimmer effect */
@keyframes shimmer {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.navbar-text .mvp-btn {
  animation: shimmer 2s infinite;
}

/* Hover and active states */
.navbar-text .mvp-btn:hover {
  color: #fff;
  transform: scale(1.05);
}

.navbar-text .mvp-btn:active {
  transform: scale(0.95);
  color: #fff !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar-text .mvp-btn {
    padding: 14px 28px;
    font-size: 16px;
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  .navbar-text .mvp-btn {
    padding: 12px 24px;
    font-size: 14px;
  }
}

/* Responsive Typography */
h1 {
  font-size: clamp(32px, 5vw, 65px);
}

h2 {
  font-size: clamp(28px, 4vw, 45px);
}

h3 {
  font-size: clamp(20px, 3vw, 30px);
}

h4 {
  font-size: clamp(18px, 2.5vw, 24px);
}

p {
  font-size: clamp(14px, 2vw, 18px);
}

.banner .tagline {
  font-size: clamp(16px, 2.5vw, 20px);
}

.banner h1 {
  font-size: clamp(40px, 6vw, 65px);
}

.txt-rotate > .wrap {
  font-size: clamp(35px, 5vw, 55px);
}

.navbar-text button {
  font-size: clamp(14px, 2vw, 18px);
  padding: clamp(12px, 2vw, 18px) clamp(20px, 3vw, 34px);
}

.video-box h2 {
  font-size: clamp(28px, 4vw, 45px);
}

.timeline-card h3 {
  font-size: clamp(16px, 2.5vw, 20px);
}

.timeline-content li {
  font-size: clamp(14px, 1.8vw, 16px);
}

.why-choose-card h3 {
  font-size: clamp(18px, 2.8vw, 24px);
}

.why-choose-card li {
  font-size: clamp(14px, 1.8vw, 16px);
}

.category-nav-link {
  font-size: clamp(14px, 1.8vw, 16px) !important;
  padding: clamp(10px, 1.5vw, 15px) clamp(20px, 2.5vw, 30px) !important;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-size: clamp(14px, 2vw, 18px);
}

/* Benefits Section Text Adjustments */
.benefits-box h3 {
  font-size: clamp(22px, 3vw, 28px);
}

.benefits-box p {
  font-size: clamp(13px, 1.6vw, 16px);
  line-height: 1.5;
}

.benefits-box strong {
  font-size: clamp(14px, 1.7vw, 17px);
}

/* Key Features Card Text Adjustments */
.proj-txtx h4 {
  font-size: clamp(18px, 2.2vw, 24px);
}

.proj-txtx span {
  font-size: clamp(12px, 1.5vw, 15px);
  line-height: 1.5;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .benefits-box h3 {
    font-size: clamp(18px, 2.5vw, 24px);
  }
  
  .benefits-box p {
    font-size: clamp(12px, 1.4vw, 14px);
  }
  
  .benefits-box strong {
    font-size: clamp(13px, 1.5vw, 15px);
  }
  
  .proj-txtx h4 {
    font-size: clamp(16px, 2vw, 20px);
  }
  
  .proj-txtx span {
    font-size: clamp(11px, 1.3vw, 13px);
  }
}

/* Floating MVP Button styles */
.floating-mvp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 25px;
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

/* Diagonal shimmer effect */
.floating-mvp-button::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(45deg);
  animation: diagonalShimmer 3s infinite;
}

/* Gradient hover effect */
.floating-mvp-button::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
  border-radius: 25px;
}

.floating-mvp-button:hover::before {
  width: 100%;
}

/* Diagonal shimmer animation */
@keyframes diagonalShimmer {
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) rotate(45deg);
  }
}

/* Float animation */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Apply both animations */
.floating-mvp-button {
  animation: float 2s ease-in-out infinite;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .floating-mvp-button {
    padding: 8px 16px;
    font-size: 12px;
    bottom: 15px;
    right: 15px;
  }
}

/* MVP Button in Navbar */
.navbar-text .mvp-btn {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  position: relative;
  background-color: #000;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
  overflow: hidden;
}

/* Gradient hover effect */
.navbar-text .mvp-btn::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text .mvp-btn:hover::before {
  width: 100%;
}

/* Box-shadow shimmer effect */
@keyframes shimmer {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.navbar-text .mvp-btn {
  animation: shimmer 2s infinite;
}

/* Hover and active states */
.navbar-text .mvp-btn:hover {
  color: #fff;
  transform: scale(1.05);
}

.navbar-text .mvp-btn:active {
  transform: scale(0.95);
  color: #fff !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar-text .mvp-btn {
    padding: 14px 28px;
    font-size: 16px;
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  .navbar-text .mvp-btn {
    padding: 12px 24px;
    font-size: 14px;
  }
}

/* Responsive navbar button adjustments */
@media (max-width: 991px) {
  .navbar-text button.vvd,
  .navbar-text .mvp-btn {
    padding: 14px 28px !important;
    font-size: 16px !important;
    width: 160px; /* Set fixed width for both buttons */
    text-align: center;
    margin: 10px !important; /* Equal margins */
  }

  .navbar-text .mvp-btn {
    margin-right: 10px !important;
  }

  .navbar-text button.vvd {
    margin-left: 10px !important;
  }
}

/* Even smaller screens */
@media (max-width: 480px) {
  .navbar-text button.vvd,
  .navbar-text .mvp-btn {
    padding: 12px 24px !important;
    font-size: 14px !important;
    width: 140px; /* Slightly smaller width for very small screens */
  }
}

/* Common styles for both buttons */
.navbar-text button.vvd,
.navbar-text .mvp-btn {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  position: relative;
  background-color: #000;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

/* Let's Connect specific hover effect */
.navbar-text button.vvd::before {
  content: "";
  background: #fff;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

/* MVP Button specific hover effect */
.navbar-text .mvp-btn::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

/* Hover states */
.navbar-text button.vvd:hover::before,
.navbar-text .mvp-btn:hover::before {
  width: 100%;
}

.navbar-text button.vvd:hover {
  color: #000;
}

.navbar-text .mvp-btn:hover {
  color: #fff;
}

/* Navbar button styles */
.navbar-text button.vvd {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button.vvd::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button.vvd:hover {
  color: #121212; /* Change text color to black on hover */
}

.navbar-text button.vvd:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar-text button.vvd {
    padding: 14px 28px;
    font-size: 16px;
    margin-left: 10px;
  }
}

/* Project card text spacing adjustments */
.project-description {
  text-align: left;
  padding: 15px !important; /* Reduced padding */
}

.project-description h4 {
  font-size: 20px;
  margin-bottom: 10px; /* Reduced margin */
  color: #fff;
  text-align: center; /* Center the title */
}

.project-description p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  line-height: 1.4; /* Reduced line height */
  margin-bottom: 10px; /* Reduced margin */
}

.benefits-section {
  margin-top: 10px; /* Reduced margin */
  padding: 12px; /* Reduced padding */
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.benefits-section ul {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.benefits-section li {
  padding: 6px 0; /* Removed left padding */
  position: relative;
  font-size: 14px; /* Slightly smaller font */
  line-height: 1.4; /* Reduced line height */
}

.benefits-section li:before {
  content: none; /* Remove the arrow */
}

/* Tab content spacing */
.project .tab-content {
  padding: 15px; /* Reduced padding */
  margin-top: 15px; /* Reduced margin */
}

/* Timeline current item indicator - enhanced visibility */
.timeline-item.current .timeline-dot {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  box-shadow: 0 0 20px rgba(170, 54, 124, 0.8);
  animation: pulse 2s infinite;
  transform: scale(1.5);
}

.timeline-item.current .timeline-card {
  border: 2px solid transparent;
  border-radius: 20px;
  background: linear-gradient (rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-shadow: 0 0 50px rgba(170, 54, 124, 0.4);
}

.timeline-item.current .timeline-date {
  color: #AA367C;
  font-weight: bold;
  text-shadow: 0 0 10px rgba(170, 54, 124, 0.3);
}

/* Enhanced pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1.5);
    box-shadow: 0 0 20px rgba(170, 54, 124, 0.8);
  }
  50% {
    transform: scale(2);
    box-shadow: 0 0 30px rgba(170, 54, 124, 0.6);
  }
  100% {
    transform: scale(1.5);
    box-shadow: 0 0 20px rgba(170, 54, 124, 0.8);
  }
}

@media (max-width: 768px) {
  .social-icon {
    margin: 15px 0;
  }
}

.social-icon-vertical {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 15px;
}

.social-icon-small {
  width: 32px;
  height: 32px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: 0.3s ease-in-out;
}

.social-icon-small svg {
  width: 15px;
  height: 15px;
  color: #fff;
}

.social-icon-small:hover {
  background: rgba(255, 255, 255, 1);
  border-color: rgba(255, 255, 255, 1);
}

.social-icon-small:hover svg {
  color: #121212;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .social-icon-vertical {
    flex-direction: row;
    margin: 15px 0;
  }
}

.social-icons-vertical {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.social-icon {
  width: 32px;
  height: 32px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: 0.3s ease-in-out;
}

.social-icon svg {
  width: 16px;
  height: 16px;
  color: #fff;
}

.social-icon:hover {
  background: rgba(255, 255, 255, 1);
  border-color: rgba(255, 255, 255, 1);
}

.social-icon:hover svg {
  color: #121212;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .social-icons-vertical {
    gap: 2px;
  }
  
  .social-icon {
    width: 38px;
    height: 38px;
  }
  
  .social-icon svg {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 480px) {
  .social-icons-vertical {
    gap: 2px;
  }
  
  .social-icon {
    width: 42px;
    height: 42px;
  }
  
  .social-icon svg {
    width: 24px;
    height: 24px;
  }
}

/* Language Switcher Styles */
.language-switcher {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.language-btn {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.language-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

.language-btn.active {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border: 1px solid transparent;
}

@media (max-width: 768px) {
  .language-switcher {
    margin: 10px 0;
    justify-content: center;
  }
}


