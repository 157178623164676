.why-choose-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
  height: 100%;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
}

.why-choose-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.why-choose-card h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #fff;
}

.why-choose-card ul {
  padding-left: 20px;
  margin-bottom: 0;
}

.why-choose-card li {
  color: #B8B8B8;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
  text-align: left;
}

.icon-wrapper {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.white-icon {
  color: white;
  font-size: 24px;
}

.category-content .row {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .why-choose-card {
    height: auto;
    margin-bottom: 20px;
  }
} 