.how-it-works-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}

.how-it-works-item {
  display: flex;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.how-it-works-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.how-it-works-item.professional:hover {
  /* Removed background gradient on hover for professionals */
}

.icon-box {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* Removed gradient background */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  flex-shrink: 0;
}

.icon {
  color: white; /* Keeping icons white */
  font-size: 24px;
}

.content {
  flex: 1;
}

.content h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #fff;
}

.content p {
  color: #B8B8B8;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .how-it-works-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .icon-box {
    margin-right: 0;
    margin-bottom: 15px;
  }
  
  .content h3 {
    font-size: 20px;
  }
}

.category-nav {
  margin-bottom: 40px;
}

.category-nav-link {
  padding: 8px 20px;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 20px;
  margin: 0 10px;
  transition: 0.3s ease-in-out;
}

.category-nav-link:hover {
  color: #fff;
  background: linear-gradient(90.21deg, #4A2FBD -5.91%, #5f5dd3 111.58%);
  border: 1px solid transparent;
}

.category-nav-link.active {
  background: linear-gradient(90.21deg, #4A2FBD -5.91%, #5f5dd3 111.58%);
  border: 1px solid transparent;
  color: #fff;
}

/* Specific styling for the professionals category */
.category-nav-link[data-category="forDoctors"]:hover,
.category-nav-link[data-category="forDoctors"].active {
  background: linear-gradient(90.21deg, #4A2FBD -5.91%, #5f5dd3 111.58%);
}

.vertical-steps {
  margin-top: 30px;
}

.step-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 25px;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.step-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.professional-steps .step-item:hover {
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
}

.step-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 28px;
  color: white;
}

.step-content {
  width: 100%;
}

.step-content h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #fff;
}

.step-content p {
  color: #B8B8B8;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .how-it-works-item h3 {
    font-size: clamp(14px, 2vw, 18px);
  }
  
  .how-it-works-item p {
    font-size: clamp(12px, 1.6vw, 14px);
  }
  
  .icon {
    font-size: clamp(18px, 2.5vw, 25px);
  }
} 